<template>
    <main>
        <div class="p-grid no-margin">
            <div class="p-col-10 p-offset-1 p-sm-8 p-sm-offset-2 p-py-5">
                <Card class="p-p-3">
                    <template #title>
                        <h1 class="p-my-0">Cafoscari Jisho Project</h1>
                    </template>
                    <template #content>
                        <p>
                            CAFOSCARI Jisho is an Italian-Japanese digital dictionary created at the 
                            Department of Asian and Mediterranean African Studies 
                            (Ca' Foscari University Venice, UCF).
                            It is based on the translation work of the JMDict library 
                            (EDRDG) carried out directly from Japanese by over 200 students 
                            and lecturers at UCF.
                        </p>
                        <p>
                            The various research projects on training students in translation work began 
                            with the first online dictionary [ITADICT] (Mariotti, Mantelli, 2011), 
                            then expanded through [a4edu] (Mantelli, Mariotti 2016) and arrived at 
                            the current CAFOSCARI Jisho (APP and WEB version), supported
                             by Mitsubishi Corporation.
                        </p>
                        <p>
                            The CAFOSCARI Jisho project (p.i. Marcella Mariotti) was conceived with a view 
                            to creating a collaborative environment where BA and MA students could work 
                            together to improve their language skills, 
                            contributing to the creation of a new Italian-Japanese dictionary. 
                            Twenty-two students were selected out of 43 applicants, 
                            who formed the translation team, 
                            led by one Senior Researhcer Alessandro Mantelli, 
                            and two Coordinators, Gaia Varone and Chiara Alessandrini.
                        </p>
                        <div class='dwlogo'>
                            <a target='_blank' href='https://apps.apple.com/it/app/cafoscari-jisho/id1608035564'><img src="@/assets/img/applestore.png"/></a>
                        </div>
                        <div class='dwlogo'>
                            <a target='_blank' href='https://play.google.com/store/apps/details?id=it.mariotti.cafoscarijisho'><img src="@/assets/img/googleplay.png"/></a>
                        </div>

                    </template>
                </Card>
                <Divider class="p-my-4"/>
                <h1 class="p-mt-0 p-mb-2 p-text-center">Our team</h1>
                <ul class="p-grid p-p-0">
                    <li class="p-col-12 p-mb-2 collaborator-item"
                        v-for="(collaborator, i) of collaborators" :key="i">
                        <Collaborator :collaborator="collaborator"/>
                    </li>
                </ul>
            </div>
        </div>
    </main>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import Card from "primevue/card";
import Divider from "primevue/divider";
// import Skeleton from "primevue/skeleton";
import Collaborator from "@/components/Collaborator.vue";

export default {
    name: "About",
    components: { Card, Divider, Collaborator },
    setup() {
        const store = useStore();

        const collaboratorsLoading = ref(false);
        const collaborators = ref();

        onMounted(async () => {
            collaboratorsLoading.value = true;
            try {
                const response = await store.dispatch("getCollaborators");
                collaborators.value = response.data;
            } catch (err) {
                collaborators.value = [];
            } finally {
                collaboratorsLoading.value = false;
            }
        });

        return {
            collaboratorsLoading,
            collaborators,
        };
    },
};
</script>

<style lang="scss" scoped>
h1 {
    font-size: 2rem;
}

.collaborator-item {
    list-style: none;
    margin: 0;
    padding: 0;
}

.dwlogo {

}
.dwlogo img {
    width:200px;
}
</style>
