<template>
    <footer>
        <p>Made with ♥ &#38; ☕&nbsp;by The JaLea Team</p>
        <!--<p>cookie policy</p>-->
    </footer>
</template>

<script>
export default {
    name: "JFooter",
};
</script>

<style scoped lang="scss">
    @import "src/assets/scss/theme/_variables";
    @import "~primeflex/src/_variables.scss";

    footer {
        width: 100%;
        padding: .8rem;

        display: flex;
        justify-content: space-between;

        font-size: .8rem;
        background-color: $primaryColor;

        p {
            margin: 0;
            padding: 0;
            color: $fontOnPrimary;
        }
    }
</style>
