<template>
    <Card class="p-px-3 p-pt-3">
        <template #title>
            <div class="p-d-flex p-flex-wrap p-ai-center profile-pic">
                <Avatar :image="collaborator.profilePicture" shape="circle" size="xlarge"/>
                <div>
                    <h2 class="p-m-0">{{ collaborator.firstName }} {{ collaborator.lastName}}</h2>
                    <small class="p-d-block" v-if="collaborator.startYear">
                        Nel team Jisho dal {{ collaborator.startYear }}
                        <template v-if="collaborator.endYear">
                            al {{ collaborator.endYear }}
                        </template>
                    </small>
                    <small class="p-d-block" v-if="collaborator.role">
                        {{ collaborator.role }}
                    </small>
                </div>
            </div>
        </template>
        <template #content>
            <p class="p-my-3" v-if="collaborator.description">{{ collaborator.description }}</p>
        </template>
    </Card>
</template>

<script>
import Avatar from "primevue/avatar";
import Card from "primevue/card";

export default {
    name: "Collaborator",
    components: { Avatar, Card },
    props: {
        collaborator: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
h2 {
    font-size: 1.5rem;
}
.profile-pic {
    column-gap: 0.5rem;
}
::v-deep(.p-card-content) {
    padding: 0 !important;
}
</style>
